<template>
  <navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <SideMenu></SideMenu>
              </div>

              <div class="col-lg-9">
                <div
                  class="tab-content myaccount-tab-content"
                  id="account-page-tab-content"
                >
                  <div
                    class="tab-pane fade show active"
                    id="account-dashboard"
                    role="tabpanel"
                    aria-labelledby="account-dashboard-tab"
                  >
                    <div
                      class=" pro-b-w-wrap"
                      style="margin-bottom: 35px"
                    >
                      <div class="row">
                        <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i class="fal fa-inbox one1"></i>
                              <h4>Wishlisted Auctions</h4>
                              <h3>{{ dasBoardData.wishlist }}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i class="fal fa-inbox one2"></i>
                              <h4>Pending Auctions</h4>
                              <h3>{{ dasBoardData.pending }}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i class="fal fa-inbox one3"></i>
                              <h4>Won Auctions</h4>
                              <h3>{{ dasBoardData.won }}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="profile-b-wrap">
                            <div class="">
                              <i class="fal fa-inbox one4"></i>
                              <h4>Delivered Auctions</h4>
                              <h3>{{ dasBoardData.delivered }}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="" style="margin-bottom: 18px">
                      <div class="l-section">
                        <!-- form start -->
                        <Form
                          @submit="onSubmit"
                          :validation-schema="schema"
                          v-slot="{ errors }"
                        >
                          <div>
                            <div class="row">
                              <div class="col-md-6">
                                <div>
                                  <h5>Basic Details</h5>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div>
                                  <a
                                    style="
                                      float: right;
                                      border: 1px solid #cbcbcb;
                                      padding: 7px;
                                      border-radius: 5px;
                                    "
                                    variant="outline-primary"
                                    @click="enableEdit()"
                                  >
                                    Click To Edit
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-md-6">
                              <label class="labelzzz">Full Name</label>
                              <Field
                                name="Name"
                                type="text"
                                v-model="currentuserinfo.custName"
                                class="form-control inputzzz"
                                :class="{ 'is-invalid': errors.Name }"
                                :disabled="invalid"
                              />
                              <div class="invalid-feedback">
                                {{ errors.Name }}
                              </div>
                            </div>
                            <div class="form-group col-md-6">
                              <label class="labelzzz">Email</label>
                              <Field
                                name="email"
                                type="text"
                                v-model="currentuserinfo.custEmail"
                                class="form-control inputzzz"
                                :class="{ 'is-invalid': errors.email }"
                                :disabled="invalid"
                              />
                              <div class="invalid-feedback">
                                {{ errors.email }}
                              </div>
                            </div>
                            <div class="form-group col-md-6">
                              <label class="labelzzz">Phone</label>
                              <div class="">
                                <div class="makeinside countryFlagimg">
                                  <img
                                    src="/assets/images/menu/flag/897.png"
                                    alt="Canada Flag"
                                    class=""
                                    style=""
                                  /><span>+1</span>
                                </div>
                                <!-- <div class="col-1 col-md-1 coutryCode">
                                  <label for="phone" class=""> +1</label>
                                </div> -->
                                <div class="phoneNUmberCls">
                                  <Field
                                    name="phone"
                                    type="number"
                                    v-model="currentuserinfo.custPhone"
                                    class="form-control inputzzz"
                                    :class="{ 'is-invalid': errors.phone }"
                                    :disabled="invalid"
                                  />
                                </div>
                              </div>

                              <div class="invalid-feedback">
                                {{ errors.phone }}
                              </div>
                            </div>

                            <div></div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <!-- <p class="profile-error">{{ message }}</p> -->
                                <button
                                  style="margin-top: 8px"
                                  type="submit"
                                  class="btn btn-danger"
                                  :disabled="invalid"
                                >
                                  UPDATE
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <!-- form end  -->
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <ResetPassword></ResetPassword>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import navbar from "@/components/navbar.vue";
import userService from "@/services/user.service";
import SideMenu from "../sideMenu.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { reactive } from "vue";
import ResetPassword from "@/components/customer/resetPassword.vue";
export default {
  name: "Profile",
  components: {
    SideMenu,
    Form,
    Field,
    navbar,
    ResetPassword,
  },
  data() {
    const schema = Yup.object().shape({
      Name: Yup.string()
        .required("User name is required")
        .min(3, "Must be more than 3 characters"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      phone: Yup.number("Must be Number")
       
        .required("This field is requried")
       
    });

    return {
      schema,
      invalid: true,
      userinfo: this.$store.state.auth.user,
      currentuserinfo: this.$store.state.auth.user,
      message: "",
      Errormessage: "",
      dasBoardData: "",
      userInformation: reactive({
        myData: JSON.parse(localStorage.getItem("user")) || null,
      }),
    };
  },
  created() {
    if (this.loggedIn) {
      //this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (this.userinfo != null) {
        if (typeof this.userinfo.customerId != "undefined") {
          this.getuserbyId(this.userinfo.customerId);
          this.getDashboardProfileFe(this.userinfo.customerId, "customer");
        }
      }
    } else {
      this.$router.push("/customerlogin");
    }
  },
  methods: {
    onSubmit(values) {
      let id = this.userinfo.customerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .updateCustomerProfile(id, values)
          .then((response) => {
            this.message = response.data.Message;
            // console.log(response.data.Message);

            this.$toast.success("profile updated successfully", {
              position: "top",
              duration: 3000,
              queue: true,
            });

            setTimeout(() => {
              location.reload();
            }, 3000);
          })
          .catch((err) => {
            this.message = err.response.data.Message;
            // console.log(err.response.data.Message);
            this.$toast.error("! Oops something went wrong. try again ", {
              position: "top",
              duration: 3000,
              queue: true,
            });
          });
      }
    },

    enableEdit() {
      this.invalid = !this.invalid;
    },
    getuserbyId(custId) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getcustomer(custId)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            /// console.log(this.currentuserinfo);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getDashboardProfileFe(custId, type) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getDashboardProfileFe(custId, type)
          .then((response) => {
            this.dasBoardData = response.data.Data[0];
            // console.log(this.dasBoardData.pending);
            window.scrollTo(0, 0);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>
<style>
.form-group {
  margin-bottom: 5px;
}
.coutryCode label {
  margin-top: 6px;
  display: block;
}
</style>
